<template>
  <div class="vld-parent" :class="{ 'select-none cursor-wait': isLoading }">
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="isFullPage"
      :lock-scroll="lockScroll"
      :blur="blur"
      loader="bars"
      color="var(--orange-500)"
      v-bind="$attrs"
    />

    <slot />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
/*! purgecss start ignore */
import "vue-loading-overlay/dist/vue-loading.css";
/*! purgecss end ignore */

export default {
  name: "LoadingOverlay",
  components: {
    Loading,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isFullPage: {
      type: Boolean,
      default: false,
    },
    lockScroll: {
      type: Boolean,
      default: false,
    },
    blur: {
      type: String,
      default: "1px",
    },
  },
};
</script>

<style lang="postcss">
/*! purgecss start ignore */
.vld-overlay {
  &:focus {
    outline: 0;
  }
}
/*! purgecss end ignore */
</style>
