











import { defineComponent } from "@vue/composition-api";

import LoadingOverlay from "@/shared/components/LoadingOverlay";

export default defineComponent({
  name: "LoadingButton",
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LoadingOverlay,
  },
});
